import React from "react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import { I18nextContext } from "../i18nextContext";

export const wrapPageElement = (
    { element, props },
    {
        i18nextOptions = {},
        generateDefaultLanguagePage = false,
        siteUrl,
        localeJsonNodeName = "locales",
    }
) => {
    if (!props) return;

    const { data, pageContext } = props;
    if (!pageContext.translations) return;

    const { routed, language, languages, originalPath, defaultLanguage, path, pageKey } =
        pageContext.i18n;

    const localeNodes = data?.[localeJsonNodeName]?.edges || [];
    const namespaces = localeNodes.map(({ node }) => node.ns);

    // We want to set default namespace to a page namespace if it exists
    // and use other namespaces as fallback
    // this way you dont need to specify namespaces in pages
    let defaultNS = i18nextOptions.defaultNS || "translation";
    defaultNS = namespaces.find(ns => ns !== defaultNS) || defaultNS;
    const fallbackNS = namespaces.filter(ns => ns !== defaultNS);

    const i18n = i18next.createInstance();

    i18n.init({
        ...i18nextOptions,
        lng: language,
        fallbackLng: defaultLanguage,
        defaultNS,
        fallbackNS,
        react: {
            useSuspense: false,
        },
    });

    localeNodes.forEach(({ node }) => {
        const parsedData = JSON.parse(node.data);
        i18n.addResourceBundle(node.language, node.ns, parsedData);
    });

    if (i18n.language !== language) {
        i18n.changeLanguage(language).then();
    }

    const pagePaths = {};
    Object.keys(pageContext.translations).forEach(language => {
        pagePaths[language] = pageContext.translations[language].path;
    });

    const context = {
        pagePaths: pagePaths,
        routed,
        language,
        languages,
        originalPath,
        defaultLanguage,
        generateDefaultLanguagePage,
        siteUrl,
        path,
        pageKey,
    };

    return (
        <I18nextProvider i18n={i18n}>
            <I18nextContext.Provider value={context}>{element}</I18nextContext.Provider>
        </I18nextProvider>
    );
};
