import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';

export const GatsbyBuildWrapElement = (element) => {
    const paths = [
        '/duo/',
        '/wybieramy-smak-lata/',
        '/wybieramy-smak-lata/wygrana/',
        '/smak-lata/',
        '/smak-lata/wygrana/',
        '/wymarzone-wesele/',
        '/wymarzone-wesele/wygrana/',
        '/konkurs-zimowy/',
        '/konkurs-zimowy/wygrana/',
        '/produkty/',
        '/produkt/',
    ];

    if (paths.includes(element.props.children?.props.value.originalPath)) {
        const queryClient = new QueryClient();
        return <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>;
    }

    return element;
};

// TODO: Zmienic aby każda strona z tego korzystała
